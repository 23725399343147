import Header from "./Header";
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import React from "react";

const Rules = ({eventId}) => {

    if (eventId === "machalscy2023") {
        return (
            <Paper className="santaSection" elevation={1} >
                <Header id={"1"} title={"Zasady, ho ho ho"}></Header>
                <ul>
                    <li>uzupełnij swoją <i>listę marzeń</i>* do 5. grudnia</li>
                    <li>budżet prezentowy 250zł - 300zł</li>
                    <li>sprawdź o czym marzy Twoja śnieżynka</li>
                </ul>
                <Typography component={'span'} sx={{ fontStyle: 'italic' }}>
                    * Mikołaj zastrzega sobie możliwość niezrealizowania listy marzeń :)
                </Typography>
            </Paper>
        );
    }

    return (
        <Paper className="santaSection" elevation={1}>
            <Header id={"1"} title={"Zasady, ho ho ho"}></Header>
            <ul>
                <li>uzupełnij swoją <i>listę marzeń</i>* do 5. grudnia</li>
                <li>budżet prezentowy to 250zł</li>
                <li>sprawdź o czym marzy Twoja śnieżynka</li>
            </ul>
            <Typography component={'span'} sx={{ fontStyle: 'italic' }}>
                * Mikołaj zastrzega sobie możliwość niezrealizowania listy marzeń :)
            </Typography>
        </Paper>
    );
}

export default Rules;
