import {Link, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Header from "./Header";
import React, {ReactElement} from "react";
import Typography from "@mui/material/Typography";
import LaunchIcon from "@mui/icons-material/Launch";


function urlify(text) {
    const urlRegex = /(https?:\/\/[^()\s]+)/gi;
    let parts = text.split(urlRegex);

    for (let i = 1; i < parts.length; i += 2) {
        parts[i] = <Link href={parts[i]}>[link <LaunchIcon fontSize={"small"}/>]</Link>;
    }
    return parts;
}

const Wish: React.FC = ({wish}): ReactElement => {
    return (
        <ListItem disablePadding={false} sx={{paddingLeft: 0, paddingRight: 0}}>
            <ListItemIcon sx={{minWidth: 30}}>
                <FiberManualRecordIcon sx={{fontSize: 12}} />
            </ListItemIcon>
            <ListItemText primary={urlify(wish)}/>
        </ListItem>
    );
};

const SnowflakeWishList = ({wishList}) => {

    if (!wishList || !wishList.length) return (
        <Typography sx={{fontStyle: 'italic'}}>
            Ta śnieżynka nie ma jeszcze listy życzeń. Sprawdź później.
        </Typography>
    )

    return (
        <>
            <Header id="🎁" title={"Lista marzeń śnieżynki"} small={true}/>
            <List>
                {wishList.map((item, index) => <Wish key={index} wish={item}/>)}
            </List>
        </>
    );
}

export default SnowflakeWishList;
