import {Avatar, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

const Header = ({id, title, small}) => {
    const size = small ? "h6" : "h5";
    return (
        <Stack direction="row" spacing={2}>
            <Avatar sx={{
                color: 'text.primary',
                bgcolor: 'primary.main'}}>{id}</Avatar>
            <Typography variant={size}>{title}</Typography>
        </Stack>
    );
}

export default Header;
