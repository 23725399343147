import '../App.css';

import {AppBar, Box, Stack, Toolbar} from '@mui/material';
import Header from "./Header";
import {functions} from "../firebase";
import {httpsCallable} from "firebase/functions";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import {Sex} from "../App";

const getEventInfo = httpsCallable(functions, 'getEventInfo');

function EventInfo({eventId}) {
    const [eventInfo, setEventInfo] = useState(null);

    useEffect(() => {
        getEventInfo({eventId})
            .then(result => {
                console.log('Got: ', result.data);
                setEventInfo(result.data);
            });
    }, [eventId]);

    if (!eventInfo) {
        return (
            <Box
                display="box"
                justifyContent="center"
                alignItems="center"
                style={{textAlign: "center"}}
            >
                <AcUnitIcon sx={{fontSize: 80}} className="rotate"/>
                <Typography>Ho ho ho!</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static" sx={{bgcolor: "primary.main"}}>
                <Toolbar>
                    <Header id={"🎄"} title={`${eventId} - podsumowanie`}/>
                </Toolbar>
            </AppBar>
            <Stack spacing={1} className="App">
                {eventInfo.map((santa) => {
                        const sex = santa.name.slice(-1) === "a" ? Sex.FEMALE : Sex.MALE;
                        const santaIcon = sex === Sex.FEMALE ? "🧑‍🎄" : "🎅";
                        const snowflake = sex === Sex.FEMALE ? "Wylosowała" : "Wylosował";

                        return <Paper className="santaSection">
                            <Header id={santaIcon} title={santa.name}></Header>
                            <ul>
                                <li>{snowflake} Śnieżynkę: {santa.shownSnowflake ? "👍" : "👎"}</li>
                                <li>Lista życzeń: {santa.wishlistSize}</li>
                                <li>Wykluczona osoba: {santa.exclude ?? "brak"}</li>
                            </ul>

                        </Paper>
                    }
                )}
            </Stack>

        </Box>
    );
}

export default EventInfo;
