import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import EventInfo from './components/EventInfo';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById('root'));

// https://www.schemecolor.com/christmas-hex-color-codes.php
// https://bareynol.github.io/mui-theme-creator/#Avatar
const theme = createTheme({
  palette: {
    mode: 'dark',
    type: 'dark',
    primary: {
      main: '#EA4630',
    },
    secondary: {
      main: '#146B3A',
    },
    background: {
      default: '#d5ddd9',
      paper: '#165B33',
    },
    // text: {
    //   primary: '#ffffff',
    //   secondary: '#e0e0e0',
    //   hint: '#cfd2cb',
    //   disabled: '#9e9e9e',
    // },
  },
});


function parseUrl() {
  const pathParams = window.location.pathname.split('/').filter(el => el)
  if (pathParams.length !== 2) {
    throw new Error("Cannot parse path parameters");
  }

  return pathParams;
}

const [eventId, santaId] = parseUrl();

let app;
if (santaId === 'info') {
  app = <EventInfo eventId={eventId}/>
} else {
  app = <App eventId={eventId} santaId={santaId}/>
}

root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        {app}
      </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
