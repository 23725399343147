import './App.css';

import {AppBar, Box, Stack, Toolbar} from '@mui/material';
import Header from "./components/Header";
import Snowflake from "./components/Snowflake";
import WishList from "./components/WishList";
import Rules from './components/Rules';
import {functions} from "./firebase";
import {httpsCallable} from "firebase/functions";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";

export const Sex = {MALE: 'Male', FEMALE: 'Female'}
const getSantaData = httpsCallable(functions, 'getSantaInfo');

function App({eventId, santaId}) {
    const [santaInfo, setSantaInfo] = useState(null);

    useEffect(() => {
        getSantaData({eventId, santaId})
            .then(result => {
                console.log('Got santaData', result.data);
                setSantaInfo(result.data);
            });
    }, [eventId, santaId]);

    if (!santaInfo) {
        return (
            <Box
                display="box"
                justifyContent="center"
                alignItems="center"
                style={{textAlign: "center"}}
            >
                <AcUnitIcon sx={{fontSize: 80}} className="rotate"/>
                <Typography>Ho ho ho!</Typography>
            </Box>
        );
    }

    const sex = santaInfo.name.slice(-1) === "a" ? Sex.FEMALE : Sex.MALE;
    const santaIcon = sex === Sex.FEMALE ? "🧑‍🎄" : "🎅";

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static" sx={{bgcolor: "primary.main"}}>
                <Toolbar>
                    <Header id={santaIcon} title={`Wesołych Świąt, ${santaInfo.name}!`}/>
                </Toolbar>
            </AppBar>

            <Stack spacing={4} className="App">

                <Rules eventId={eventId}/>
                <WishList eventId={eventId} santaId={santaId} sex={sex}/>
                <Snowflake eventId={eventId} santaId={santaId} match={santaInfo.match}/>

            </Stack>
        </Box>
    );
}

export default App;