import React, {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Header from "./Header";
import {httpsCallable} from "firebase/functions";
import {Error} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {functions} from "../firebase";
import SnowflakeWishList from "./SnowflakeWishList";
import {Box} from "@mui/material";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import confetti from 'canvas-confetti'

const findSantaMatchCall = httpsCallable(functions, 'findMySnowflake');

const Snowflake = ({eventId, santaId, match}) => {
    const [localMatch, setLocalMatch] = useState(match);
    const [loading, setLoading] = useState(null);
    const snowflakeRef = React.useRef(null);

    function findSantaMatch(eventId, santaId) {
        console.log(`Finding match for ${eventId}/${santaId}`);
        setLoading(true);
        findSantaMatchCall({eventId, santaId})
            .then(match => {
                console.log('Found match', match);
                setLocalMatch(match.data.match);

                setTimeout(() => {
                    setLoading(false);
                    confetti({
                        particleCount: 150,
                        angle: 60,
                        spread: 70,
                        origin: { y:0.9, x: 0 },
                    });
                    confetti({
                        particleCount: 150,
                        angle: 120,
                        spread: 65,
                        origin: { y:0.9, x: 1 },
                    });
                }, 2000);
            })
    }

    if (localMatch?.error) return (
        <Paper elevation={1} style={{padding: 20}}>
            <Error/>
            <Typography> {localMatch.error}</Typography>
        </Paper>
    )

    if (loading) return (
        <Paper className="santaSection" elevation={1} ref={snowflakeRef}>
            <Box
                display="box"
                justifyContent="center"
                alignItems="center"
                style={{textAlign: "center"}}
            >
                <AcUnitIcon sx={{fontSize: 80}} className="rotate"/>
                <Typography>Trwa losowanie</Typography>
            </Box>
        </Paper>
    );

    if (!localMatch) return (
        <Paper className="santaSection" elevation={1} ref={snowflakeRef}>
            <Header id={3} title={"Wylosuj osobę do obdarowania"}/>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{padding: 20}}
            >
                <Button startIcon={<AcUnitIcon/>}
                        variant="contained"
                        onClick={() => findSantaMatch(eventId, santaId)}>
                    Wylosuj śnieżynkę
                </Button>
            </Box>
        </Paper>
    );

    return (
        <Paper className="santaSection" elevation={1} ref={snowflakeRef}>
            <Header id={3} title={"Moja śnieżynka to:"}/>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="h1" gutterBottom>{localMatch.name}</Typography>
            </Box>
            <SnowflakeWishList wishList={localMatch.wishList}/>
        </Paper>
    );
}

export default Snowflake;
