import {Collapse, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, Stack, TextField} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {db} from '../firebase.js';
import {addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, serverTimestamp} from "firebase/firestore";
import Header from "./Header";
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import React, {ReactElement, useEffect, useState} from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Typography from "@mui/material/Typography";
import LaunchIcon from '@mui/icons-material/Launch';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {TransitionGroup} from "react-transition-group";
import {Sex} from "./../App.js"

const listDBPath = (eventId, santaId) => `${eventId}/${santaId}/wishList`
const dbCollection = (eventId, santaId) => collection(db, listDBPath(eventId, santaId))

function urlify(text) {
    const urlRegex = /(https?:\/\/[^()\s]+)/gi;
    let parts = text.split(urlRegex);

    for (let i = 1; i < parts.length; i += 2) {
        parts[i] = <Link key={i} href={parts[i]}>[link <LaunchIcon fontSize={"small"}/>]</Link>;
    }
    return parts;
}

const Wish: React.FC = ({wish, deleteWishFn}): ReactElement => {
    return (
        <ListItem disablePadding={false} sx={{paddingLeft: 0, paddingRight: 0}} key={wish.id}>
            <ListItemIcon sx={{minWidth: 30}}>
                <FiberManualRecordIcon sx={{fontSize: 12}}/>
            </ListItemIcon>
            <ListItemText>{urlify(wish.wish)}</ListItemText>
            <IconButton onClick={() => deleteWishFn(wish.id)}>
                <DeleteIcon/>
            </IconButton>
        </ListItem>
    );
};

const WishList = ({eventId, santaId, sex}) => {
    const [wishList, setWishlist] = useState([]);
    const [input, setInput] = useState('');

    useEffect(() => {
        const q = query(dbCollection(eventId, santaId), orderBy('timestamp', 'desc'));
        onSnapshot(q, (snapshot) => {
            setWishlist(snapshot.docs.map(doc => ({
                id: doc.id,
                wish: doc.data().wish
            })))
        });
    }, [eventId, santaId]);
    const addWish = (e) => {
        e.preventDefault();
        const wish = {
            wish: input,
            timestamp: serverTimestamp()
        }
        addDoc(dbCollection(eventId, santaId), wish)
        setInput('')
    };

    const deleteWish = (id) => {
        deleteDoc(doc(db, listDBPath(eventId, santaId), id))
    }

    const inputPlaceholder = sex === Sex.MALE ? 'chciałbym...' : 'chciałabym...';

    return <Paper className="santaSection" elevation={1}>
        <Header id={"2"} title={"Moja lista marzeń"}/>
        <Typography sx={{fontStyle: 'italic'}}>
            Pomóż swojemu Tajnemu Mikołajowi
        </Typography>

        <ul>
            <li>dodaj przynajmniej 3 rzeczy</li>
            <li>możesz wklejać linki</li>
            <li>określ szczegóły (rozmiar, kolor itp.) ponieważ Mikołaj może nie znać Twoich preferencji :)</li>
        </ul>

        <form onSubmit={addWish} style={{padding: 0}}>
            <Stack direction="row" spacing={1} justifyContent="center">
                <TextField id="outlined-basic"
                           label={inputPlaceholder}
                           variant="outlined"
                           size="small"
                           value={input}
                           sx={{
                               bgcolor: 'rgba(255, 255, 255, 0.15)',
                               width: '80%'
                           }}
                           onChange={e => setInput(e.target.value)}
                />
                <Button startIcon={<AddCircleOutlineIcon/>}
                        variant="contained"
                        onClick={addWish}>Dodaj</Button>
            </Stack>
        </form>
        <List>
            <TransitionGroup>
                {wishList.map((item) =>
                    <Collapse key={item.id}>
                        <Wish wish={item} deleteWishFn={deleteWish}/>
                    </Collapse>
                )}
            </TransitionGroup>
        </List>
    </Paper>
}

export default WishList;
