// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore';
import {connectFunctionsEmulator, getFunctions} from "firebase/functions";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCHdCAoisVnFGU0jJBUsDzTOCLBOCCvgf4",
  authDomain: "secret-santa-3271a.firebaseapp.com",
  projectId: "secret-santa-3271a",
  storageBucket: "secret-santa-3271a.appspot.com",
  messagingSenderId: "380074813648",
  appId: "1:380074813648:web:b4b8459fdce06f24d116d5",
  measurementId: "G-48CTQTLERD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-central2");
// connectFirestoreEmulator(db, '192.168.0.227', 8080);
// connectFunctionsEmulator(functions, "192.168.0.227", 5001);

export { db, functions }
